<template>
  <!-- prettier-ignore -->
  <div
    :id="productWidgetId"
    ref="productWidgetRef"
    class="productwidget"
    :app-language="locale"
    :app-name="appName$"
    :app-version="version$"
  >

    <template v-if="!isReadyWithPartner">
      <loading-alt />
    </template>

    <template v-else>
      <template v-if="isEntryLoginActive || (!showPreview && basketStore.basket.persons.length === 0)">
        <productwidget-entry
          ref="productWidgetEntryRef"
          @open-form="data => openModal(data)"
          @restart="restartApplication()"
        >
          <template #login>
            <login-action
              v-if="isLoginEnabled || isEntryLoginActive"
              ref="entryLoginRef"
              :caller-id="productWidgetId"
              :channel="CHANNEL.PRODUCTWIDGET"
              :modal-id="entryLoginModalId"
            />
          </template>
        </productwidget-entry>
      </template>

      <template v-else>
        <productwidget-main
          :is-preview="showPreview"
          :persons="displayPersons.mutable"
          :products="$props.products"
          :selected-product-id="$props.selected"
          :source="$props.source"
          @open-form="modalRef.open()"
          @restart="restartApplication()"
        >
          <template #login>
            <login-action
              v-if="isLoginEnabled || isMainLoginActive"
              ref="mainLoginRef"
              :caller-id="productWidgetId"
              :channel="CHANNEL.PRODUCTWIDGET"
              :modal-id="mainLoginModalId"
            />
          </template>
        </productwidget-main>
      </template>

      <basic-modal
        :id="`${$props.products}-persons-modal`"
        ref="modalRef"
        :modal-title="$t('screen.productWidget.form.title')"
        size="midi"
        type="raw"
      >
        <productwidget-form
          :initial-person="initialPerson"
          :modal-ref="modalRef"
          @close-modal="modalRef.close()"
        />
      </basic-modal>
    </template>
  </div>
</template>

<script setup>
import { computed, inject, ref } from 'vue'

import { ReactivityUtil } from '@/utils/Reactivity'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import useApplication from '@/hooks/useApplication'
import useAuth from '@/hooks/useAuth'
import useI18n from '@/hooks/useI18n'
import usePersonDetails from '@/hooks/usePersonDetails'

import LoadingAlt from '@/components/Container/LoadingAlt'
import ProductwidgetEntry from '@/modules/ProductWidget/components/Entry'
import ProductwidgetMain from '@/modules/ProductWidget/components/Main'
import ProductwidgetForm from '@/modules/ProductWidget/components/Form'
import { BasicModal } from '@/components/Basic'
import { LoginAction } from '@/components/Login'

import { CHANNEL } from '@/config/constants'
import { SOURCE } from '@/config/events'

// HOOKS
const { locale } = useI18n()
const { isReadyWithPartner } = useApplication()
const { restartApplication } = useAuth()
const { getPersonsByMutability } = usePersonDetails()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')
const props = defineProps({
  hasPreview: {
    type: Boolean,
    default: false,
  },

  products: {
    type: String,
    required: true,
  },

  selected: {
    type: String,
    default: 'A00Produkt',
  },

  source: {
    type: String,
    default: SOURCE.PRODUCT_WIDGET,
  },
})

// DATA
const entryLoginRef = ref(null)
const mainLoginRef = ref(null)
const modalRef = ref(null)
const initialPerson = ref()
const productWidgetEntryRef = ref(null)

// COMPUTED
const displayPersons = computed(() => {
  return getPersonsByMutability()
})

const isLoginEnabled = computed(() => {
  return (
    basketStore.basket.channel !== CHANNEL.ALVA &&
    !partnerStore.isPrivateInsurer.value &&
    !partnerStore.isBroker.value &&
    !partnerStore.isCollective.value &&
    !basketStore.basket.existingCustomer
  )
})

const isEntryLoginActive = computed(() => {
  return entryLoginRef.value?.actionModalRef?.isActive
})
const isMainLoginActive = computed(() => {
  return mainLoginRef.value?.actionModalRef?.isActive
})

const entryLoginModalId = computed(() => {
  return `product-widget-entry-login-modal-${productIds.value}`
})

const mainLoginModalId = computed(() => {
  return `product-widget-main-login-modal-${productIds.value}`
})

const productIds = computed(() => {
  return props.products.replace(/,/g, '-')
})

const productWidgetId = computed(() => {
  return `product-widget-${productIds.value}`
})

const showPreview = computed(() => {
  return props.hasPreview && basketStore.basket.persons.length === 0
})

// METHODS
function openModal(formData) {
  initialPerson.value = ReactivityUtil.clone(formData)
  modalRef.value.open()
}
</script>

<style scoped>
.productwidget {
  border-radius: var(--border-radius-res-m);
  background: var(--surface);
  padding: var(--dotcom-responsive-offset-responsive);
  box-shadow: var(--elevation-level-3);
  min-width: 320px;

  position: relative;
}

@media (--v-medium) {
  .productwidget {
    padding: var(--dotcom-responsive-gutter-responsive);
  }
}
</style>
