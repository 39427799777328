<template>
  <div
    v-if="!isDisabled"
    class="action"
  >
    <basic-button
      v-if="$props.product.selected && $props.isDirty"
      :label="$t('form.apply')"
      @click="emit('apply')"
    />

    <basic-button
      v-else-if="$props.isEditable"
      :hierarchy="buttonHierarchy"
      :label="$t(title)"
      @click="actionHandler"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import useI18n from '@/hooks/useI18n'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'

import { BasicButton } from '@/components/Basic'

// HOOKS
const { te } = useI18n()

// INIT
const emit = defineEmits(['add', 'apply', 'search', 'remove'])
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },

  hasLoadingIndicator: {
    type: Boolean,
    default: false,
  },

  isDirty: {
    type: Boolean,
    default: false,
  },

  isEditable: {
    type: Boolean,
    default: true,
  },

  personId: {
    type: String,
    required: true,
  },

  product: {
    type: Object,
    required: true,
  },
})

// COMPUTED
const buttonHierarchy = computed(() => {
  return !props.product.selected ? 'secondary' : 'tertiary'
})

const isDisabled = computed(() => {
  return props.disabled || (!props.product.valid && !props.product.selected)
})

/**
 * Only show search doctor if the product is not selected.
 * If it's selected and the doctor is missing,
 * a warning message and action will be displayed in the product doctor.
 */
const needsDoctor = computed(() => {
  return props.product.medicalOffice && !props.product.doctor && !props.product.selected
})

const title = computed(() => {
  if (!props.product.valid && !props.product.selected) {
    return 'product.unavailable'
  } else if (props.product.selected) {
    return getContent(props.product.productId, 'actions.remove')
  } else {
    return getContent(props.product.productId, 'actions.add')
  }
})

const willReplaceIds = computed(() => {
  const { willReplace } = usePersonSpecificProduct()

  return willReplace(props.personId, props.product.productId)
})

// METHODS
function actionHandler() {
  if (isDisabled.value) return
  if (props.product.medicalOffice && needsDoctor.value) {
    emit('search')
  } else if (!props.product.selected && props.product.valid) {
    const productId = willReplaceIds.value
    emit('add', { replace: !!productId, productId })
  } else if (props.product.selected) {
    emit('remove')
  }
}

function getContent(productId, key) {
  const productPath = `content.products.${productId}`
  return te(`${productPath}.${key}`) ? `${productPath}.${key}` : `product.${key}`
}
</script>

<style scoped>
.action {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-04);

  .button.plain {
    color: var(--c-primary-neutral-1) !important;

    .icon {
      color: var(--c-primary-neutral-1);
      margin: 0 0 0 5px;
    }
  }

  .action--disabled button,
  .action--disabled button:hover {
    cursor: not-allowed !important;
    border-color: transparent !important;
    background: none !important;
    color: var(--c-primary-color-3) !important;
    fill: var(--c-primary-color-3) !important;
  }

  .action__unavailable {
    flex-flow: row-reverse;
  }
}

@media (--v-medium) {
  .action {
    flex-direction: row;
    gap: var(--fixed-spacing-fix-06);
  }
}

@media (--v-large) {
}

@media (--v-wide) {
}

@media (--v-ultra) {
}

@media (--v-max) {
}
</style>
