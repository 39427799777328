<template>
  <!-- prettier-ignore -->
  <div
    v-if="isReadyWithPartner"
    class="fab-widget"
    :app-language="selectedLanguage"
    :app-name="appName$"
    :app-version="version$"
  >
    <fab-container/>
  </div>
</template>

<script setup>
import { inject } from 'vue'

import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'

import FabContainer from '@/components/Fab/FabContainer'

// HOOKS
const { isReadyWithPartner } = useApplication()
const { selectedLanguage } = useI18n()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')
</script>

<style name="global" scoped>
* {
  & :deep(button) {
    font-family:
      Open Sans,
      sans-serif !important;
    font-weight: bold;
  }

  & :deep(h3),
  & :deep(h4) {
    font-family:
      Open Sans,
      sans-serif !important;
    color: var(--c-primary-neutral-1);
  }
}
</style>
