import { createApp, setup } from '@/setup'

import { logInfo } from '@/helpers/Logger'

// DATA
const instanceKey = '__vueInstance__'
const ROOT_EL = document.body
const observeConfig = { attributes: false, childList: true, subtree: true }

export function registerContinuously(component, selector, props) {
  const observer = observe(() => {
    const nodes = document.querySelectorAll(selector)
    nodes.forEach(node => {
      register(component, node, props)
    })
  })
  observer.observe(ROOT_EL, observeConfig)
}

export function registerOnce(component, selector, props) {
  const el = document.querySelector(selector)

  if (!el) {
    const observer = observe(() => {
      const node = document.querySelector(selector)

      // node present => register and cancel observer
      if (node) {
        register(component, node, props)
        observer.disconnect()
      }
    })
    observer.observe(ROOT_EL, observeConfig)

    // container is present and no instance has been found
  } else if (!el.getAttribute(instanceKey)) {
    register(component, el, props)
  }
}

function observe(callback) {
  return new MutationObserver(mutations => {
    for (const mutation of mutations) {
      if (mutation.type === 'childList') {
        callback()
      }
    }
  })
}

function preProcessProps(element, props) {
  return props(element.dataset)
}

function register(component, element, props) {
  // Already registered? Abort!
  if (element.getAttribute(instanceKey)) return

  // preProcess props
  props = props ? preProcessProps(element, props) : element.dataset

  const instance = setup(createApp(component, props))
  instance.mount(element)

  element.setAttribute(instanceKey, instance._uid)
  logInfo(['%cMOUNTED WIDGET', 'color: green', instance._component.__name])
}
