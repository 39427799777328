<template>
  <!-- prettier-ignore -->
  <div class="indicator">
    <div class="controls">
      <button
        class="controls__arrow back"
        :disabled="selectedIndex === 0"
        @click="setIndex(selectedIndex - 1)"
      >
        <the-icon
          art="solid"
          name="angle-down"
          :rotation="90"
        />
      </button>
      <button
        v-for="(_, idx) in $props.quantity"
        :key="idx"
        class="controls__dot"
        :class="{'controls__dot--selected': idx === selectedIndex}"
        @click="setIndex(idx)"
      />
      <button
        class="controls__arrow next"
        :disabled="selectedIndex + 1 === $props.quantity"
        @click="setIndex(selectedIndex + 1)"
      >
        <the-icon
          art="solid"
          name="angle-down"
          :rotation="270"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const emit = defineEmits(['select'])
const props = defineProps({
  defaultIndex: {
    type: Number,
    default: 0,
  },

  quantity: {
    type: Number,
    required: true,
  },
})

// DATA
const selectedIndex = ref(props.defaultIndex)

// COMPUTED

// METHODS
function setIndex(idx) {
  selectedIndex.value = idx
  emit('select', selectedIndex.value)
}

// EPILOGUE
defineExpose({ setIndex })
</script>

<style>
:root,
.surface-default {
  --dvp-indicator-color: var(--Interaction-States-enabled-default);
  --dvp-indicator-color-hovered: var(--Interaction-States-hovered-default-a);
  --dvp-indicator-color-active: var(--Interaction-States-pressed-default-a);
  --dvp-indicator-arrow-background-color: transparent;
}

.surface-alternative {
  --dvp-indicator-color: var(--Interaction-States-enabled-extended);
  --dvp-indicator-color-hovered: var(--Interaction-States-hovered-extended-a);
  --dvp-indicator-color-active: var(--Interaction-States-pressed-extended-a);
  --dvp-indicator-arrow-background-color: transparent;
}

.surface-inverted {
  --dvp-indicator-color: var(--Interaction-States-enabled-inverse);
  --dvp-indicator-color-hovered: var(--Interaction-States-hovered-inverse-a);
  --dvp-indicator-color-active: var(--Interaction-States-pressed-inverse-a);
  --dvp-indicator-arrow-background-color: rgba(155, 247, 163, 0.3);
}
</style>

<style scoped>
.indicator {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-02);
  align-items: center;
  padding: var(--dotcom-responsive-spacing-res-s) 0 0 0;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--dotcom-responsive-spacing-res-m);

  & > button {
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
  }
}

.controls__arrow {
  background-color: var(--dvp-indicator-arrow-background-color);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  transition-property: color;
  transition-timing-function: ease-in;
  transition-duration: 100ms;

  &:deep(.icon) {
    color: var(--dvp-indicator-color);
    font-size: 24px;
  }

  &:hover:deep(.icon) {
    color: var(--dvp-indicator-color-hovered);
  }

  &:active:deep(.icon) {
    color: var(--dvp-indicator-color-active);
  }

  &.back:deep(.icon) {
    margin: 0 var(--fixed-spacing-fix-01) 0 0;
  }

  &.next:deep(.icon) {
    margin: 0 0 0 var(--fixed-spacing-fix-01);
  }

  &[disabled] {
    pointer-events: none;

    &:deep(.icon) {
      color: var(--Intercation-States-disabled-default);
    }
  }
}

.controls__dot {
  height: 12px;
  width: 12px;
  background-color: var(--dvp-indicator-color);
  border-radius: 12px;
  transition-property: color, width;
  transition-timing-function: ease-in;
  transition-duration: 100ms;

  &:hover {
    background-color: var(--dvp-indicator-color-hovered);
  }

  &:active {
    background-color: var(--dvp-indicator-color-active);
  }

  &--selected {
    width: 42px;
  }
}

.controls__label {
  display: flex;
  flex-direction: row;
  gap: var(--fixed-spacing-fix-01);
}
</style>
