import { required } from '@vuelidate/validators'

import { DateTimeUtil } from '@/utils/DateTime'
import { ValidatorsUtil } from '@/utils/Validators'
import { dateOfBirth } from '@/validations/components'
import { GENDER } from '@/config/constants'

export default {
  address: {
    zipcode: required,
    location: ValidatorsUtil.isLocation,
  },
  dateOfBirth: {
    ...dateOfBirth,
    dateRange: function (value, model) {
      return model.gender !== GENDER.UNBORN || DateTimeUtil.isInFuture(value)
    }
  },
  gender: {
    required,
  },
}
