<template>
  <!-- prettier-ignore -->
  <div
    class="ovp-basket-widget ovp-a11y-container"
    :app-language="selectedLanguage"
    :app-name="appName$"
    :app-version="version$"
  >
    <basic-context-menu
      v-model="basketWidgetOpen"
      class="basket__widget"
      has-full-width-on-mobile
    >
      <template #trigger>
        <button
          class="trigger trigger-btn"
          @click="basketWidgetOpen = !basketWidgetOpen"
        >
          <the-icon
            art="light"
            name="cart-shopping"
          />
          <div
            class="badge"
            v-text="selectedProductCount"
          />
        </button>
      </template>

      <template #content>
        <basket-container
          :configurator-url="configuratorUrl"
          @close="basketWidgetOpen = false"
        />
      </template>
    </basic-context-menu>
  </div>
</template>

<script setup>
import { computed, inject, ref } from 'vue'

import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'
import usePersonDetails from '@/hooks/usePersonDetails'

import BasketContainer from '@/components/BasketWidget/BasketContainer'
import { BasicContextMenu } from '@/components/Basic'

// HOOKS
const { getDvpUrl } = useApplication()
const { selectedLanguage } = useI18n()
const { selectedProductCount } = usePersonDetails()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')

// DATA
const basketWidgetOpen = ref(false)

// COMPUTED
const configuratorUrl = computed(() => {
  return getDvpUrl(selectedLanguage.value)
})
</script>

<style>
/* note: is needed for dotcom */
.dotcom__header--transparent:not(.dotcom__header--sticky):not(.dotcom__header--hovered) .trigger {
  color: var(--colors-primary-1000) !important;
}
</style>

<style scoped>
.ovp-basket-widget {
  .basket__widget {
    .trigger,
    :deep(.trigger) {
      background: none;
      border: none;
      font-size: 28px;
      color: var(--on-surface);
      position: relative;
      display: inline-block;

      padding: 0;
      padding-inline: 0;
      padding-block: 0;

      &:hover {
        cursor: pointer;
        color: var(--Interaction-States-pressed-default-a);
      }
    }

    .badge {
      position: absolute;
      top: 4px;
      right: 0;
      background-color: var(--primary);
      color: var(--on-attention);
      border-radius: 50%;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      box-sizing: content-box;
      box-shadow: 0 0 0 3px var(--surface-ext-b-low);
    }
  }
}
</style>
